<template>
	<client-page>
		<section class="section">
			<div class="container">
				<h2 class="login-title"><span>{{$t('common.로그인')}}</span></h2>

				<div class="login-container">
					<div class="login-rowgroup">
						<div class="login-row mt-0">
							<v-text-field v-model="username" outlined hide-details placeholder="아이디" type="text"></v-text-field>
						</div>
						<div class="login-row mt-10 mt-md-16">
							<v-text-field v-model="password" @keydown.enter="login" outlined hide-details placeholder="비밀번호" type="password"></v-text-field>
						</div>
						<div class="login-row login-row--submit mt-16 mt-md-30">
							<v-btn @click="login" block large color="primary">{{$t('common.로그인')}}</v-btn>
						</div>
						<div class="login-row login-row--links">
							<v-checkbox v-model="remember" :value="true" :label="$t('common.아이디 저장')" hide-details></v-checkbox>

							<ul class="links">
								<li><a class="link" href="/join">회원가입</a></li>
								<li><a class="link" href="/login/find-id/">{{$t('common.아이디 찾기')}}</a></li>
								<li><a class="link" href="/login/find-password/">{{$t('common.비밀번호 찾기')}}</a></li>
							</ul>
						</div>
					</div>
					<!-- 회원가입/소셜버튼 -->
					<!-- <div class="login-rowgroup login-rowgroup--buttons">
						<div class="login-row">
							<v-btn block x-large color="primary" to="/join/type">회원가입</v-btn>
						</div>
						<div class="login-row">
							<kakao-login client_id="53ce3af6a7f73f20faa30fef193adcfb"></kakao-login>
						</div>
					</div> -->
				</div>
			</div>
		</section>
	</client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import CryptoAES from '@/plugins/crypto-aes';
import KakaoLogin from "@/components/plugins/kakao/kakao-login.vue";

export default{
	components: {
		ClientPage,
        KakaoLogin,
	},
	data(){
		return {
			username: "",
			password: "",

			remember: false
		}
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
			if(this.$cookies.get('username')) this.username = this.$cookies.get('username');
			// if(this.$cookies.get('password')) this.password = CryptoAES.decrypt(this.$cookies.get('password'));
		},
		async login(){
			try{
				await this.$store.dispatch("login", { username: this.username, password: this.password, remember: this.remember });

				if(this.remember){
					this.$cookies.set('username', this.username);
					this.$cookies.set('password', CryptoAES.encrypt(this.password));
				}

				this.$router.push('/');
			}
			catch(error){
				console.error(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message);
			}
		},
	},
}
</script>
