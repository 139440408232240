var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "login-title"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('common.로그인')))])]), _c('div', {
    staticClass: "login-container"
  }, [_c('div', {
    staticClass: "login-rowgroup"
  }, [_c('div', {
    staticClass: "login-row mt-0"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "아이디",
      "type": "text"
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  })], 1), _c('div', {
    staticClass: "login-row mt-10 mt-md-16"
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "비밀번호",
      "type": "password"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.login.apply(null, arguments);
      }
    },
    model: {
      value: _vm.password,
      callback: function ($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('div', {
    staticClass: "login-row login-row--submit mt-16 mt-md-30"
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "large": "",
      "color": "primary"
    },
    on: {
      "click": _vm.login
    }
  }, [_vm._v(_vm._s(_vm.$t('common.로그인')))])], 1), _c('div', {
    staticClass: "login-row login-row--links"
  }, [_c('v-checkbox', {
    attrs: {
      "value": true,
      "label": _vm.$t('common.아이디 저장'),
      "hide-details": ""
    },
    model: {
      value: _vm.remember,
      callback: function ($$v) {
        _vm.remember = $$v;
      },
      expression: "remember"
    }
  }), _c('ul', {
    staticClass: "links"
  }, [_c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/join"
    }
  }, [_vm._v("회원가입")])]), _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/login/find-id/"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.아이디 찾기')))])]), _c('li', [_c('a', {
    staticClass: "link",
    attrs: {
      "href": "/login/find-password/"
    }
  }, [_vm._v(_vm._s(_vm.$t('common.비밀번호 찾기')))])])])], 1)])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }